import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper";

import { useModal } from "../components/useModal";

import {
  IoRestaurantOutline,
  IoConstructOutline,
  IoStorefrontOutline,
  IoFastFoodOutline,
  IoSunnyOutline,
  IoPartlySunnyOutline,
  IoChatbubblesOutline,
  IoPeopleOutline,
  IoArrowForward,
  IoPlay,
  IoPlayOutline,
  IoHelpBuoyOutline,
  IoBookOutline,
  IoDesktopOutline,
  IoPeopleCircleOutline,
  IoArrowBack,
} from "react-icons/io5";

import { IoMdOpen } from "react-icons/io";

import BackgroundImage from "gatsby-background-image";

import {
  CallToAction,
  CustomerQuote,
  Modal,
  LogoScroller,
  IframeContainer
} from "../components/site/";
import {
  Button,
  Container,
  Wrapper,
  Row,
  Box,
  FlexButtons,
} from "../components/util";

import Image from "../components/image";
import Layout from "../components/layout";
import Seo from "../components/seo";

import GetBackToBusiness from "../images/home/GetBackToBusiness_Text.svg"
import Autopilot from "../images/home/Autopilot.svg"
import PayrollAwardBadge from "../images/home/PayrollAwardBadge.svg";

import video_payroll from "../images/home/videos/payroll_video.mp4";
import video_time from "../images/home/videos/time_video.mp4";
import video_employee from "../images/home/videos/employee_video.mp4";

import Droppah_Logo from "../images/logos/Droppah/Droppah_Single.svg";
import Xero_Logo from "../images/logos/Xero/Xero_White_Single.svg";
import MyHR_Logo from "../images/logos/Other/MyHR_Logo_White.svg";
import IRD_Logo from "../images/logos/IRD/IRD_Full_White.svg";
import Akahu_Logo from "../images/logos/Other/Akahu_Logo_White.svg";
import PowerBI_Logo from "../images/logos/Other/PowerBI_Full.svg";

const AwardBadge = styled.img`
  position: absolute;
  right: 0;
  bottom: 40px;
  width: 250px;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    position: relative;
    bottom: 0;
    width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const BlueInterest = styled.div`
  height: 120%;
  width: 100vw;
  position: absolute;
  top: 50%;
  background-color: #00adef;
  transform: translateY(-50%);
  border-radius: 30px;

  &.-left {
    left: -30px;
  }

  &.-right {
    right: -30px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: calc(100vw + 30px);
    border-radius: 0;
  }
`;

const IntegrationBox = styled(Box)`
  width: calc(100% / 6);
  aspect-ratio: 1 / 1;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  box-shadow: ${(props) => props.theme.styles.shadow};

  img {
    width: 100%;

    &.-square {
      max-height: 80px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    width: 100%;
    max-width: 100px;
    border-radius: 20px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CustomSwiper = styled(Swiper)`
  .prev,
  .next {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 1000px;
    box-shadow: ${(props) => props.theme.styles.shadow};
    aspect-ratio: 1 / 1;
    height: unset;
    width: 70px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    cursor: pointer;

    &::after {
      display: none;
    }

    svg {
      color: #000;
      font-size: 2rem;
    }

    &:hover {
      background-color: #f4f4f4;
    }

    &:active {
      background-color: #ddd;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      top: unset;
      bottom: 0;
      transform: translateY(0);
    }
  }

  .prev {
    left: 25px;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      left: 0;
    }
  }

  .next {
    right: 25px;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      right: 0;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    box-shadow: ${(props) => props.theme.styles.shadow};
    border-radius: 10px;
  }
`;

const CustomSwiperSlide = styled(SwiperSlide)`
  padding: 60px;
  border-radius: 10px;
  box-sizing: border-box;

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
  }
`;

const SlideContent = styled(Box)`
  box-shadow: ${(props) => props.theme.styles.shadow};
  border-radius: 10px;
  padding: 30px;

  .customer-image {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    .gatsby-image-wrapper {
      border-radius: 10px;
      height: 100%;

      @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
        border-radius: 10px 10px 0 0;
      }
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      position: relative;
    }
  }

  .customer-quote {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: ${(props) => props.theme.styles.shadow};
    padding: 40px;
    margin-left: auto;

    h2 {
      line-height: 1.3;
    }

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      padding: 30px 30px 100px;
      box-shadow: none;
      border-radius: 0 0 10px 10px;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
    padding: 0;
    box-shadow: none;
  }
`;

const IndustryBox = styled(Link)`
  display: flex;
  flex-direction: column;
  text-decoration: none;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.styles.shadow};
  padding: 30px;

  &:hover {
    background-color: #f4f4f4;
  }

  &:active {
    background-color: #ddd;
  }

  &.-flex {
    flex-direction: row;
    align-items: center;

    svg {
      margin-right: 7px;
      margin-bottom: 0;
    }
  }

  svg {
    font-size: 2.5rem;
    margin-bottom: 7px;
    margin-right: 0;

    &.-arrow {
      font-size: 1.5rem;
      margin-bottom: 0;
    }
  }
`;

const SupportBox = styled(Box)`
  background-color: #fff;
  border-radius: 10px;
  box-shadow: ${(props) => props.theme.styles.shadow};
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    margin-bottom: 50px;

    @media (max-width: ${(props) => props.theme.breakpoints.mobile}) {
      margin-bottom: 20px;
    }

    svg {
      font-size: 3.2rem;
      color: #00adef;
    }
  }
`;

const Home = (props) => {
  var [embedLink, setEmbedLink] = useState("");
  var [embedTitle, setEmbedTitle] = useState("");
  const [itemModalOpen, setItemModalOpen] = useModal();

  var visibleY = function (element) {
    var position = element.getBoundingClientRect();
    if (
      position.top >= 0 /* < change this value for different boundaries */ &&
      position.bottom <= window.innerHeight
    ) {
      return true;
    }
    return false;
  };

  checkVideos();

  if (typeof document !== "undefined" && typeof window !== "undefined") {
    document.addEventListener("scroll", (event) => {
      checkVideos();
    });
  }

  function checkVideos() {
    if (typeof document !== "undefined" && typeof window !== "undefined") {
      let videos = document.querySelectorAll("video");
      videos.forEach((video) => {
        if (visibleY(video)) {
          video.muted = true;
          video.play();
        } else {
          video.pause();
        }
      });
    }
  }

  function handleModal(url, title) {
    setItemModalOpen(true);
    setEmbedLink(url);
    setEmbedTitle(title);
  }

  const Features = [
    {
      title: "Spend less time on payroll",
      description:
        "Paying your team shouldn’t take ages, so we’ve designed it to be easy. Pay your wages in just a few clicks. You can even put your payroll on Autopilot.",
      linkText: "Learn about Payroll",
      link: process.env.GATSBY_SITE_URL + '/payroll',
      video: video_payroll,
      quote:
        "Recently an accounting firm was pitching me for business. They told me they could do my payroll in just two hours a week. I told them it only takes me 10 minutes.",
      name: "Rochelle Harrison",
      company: "Wellington Chocolate Factory",
      pic: "Rochelle_Circle.png",
    },
    {
      title: "Track time, don’t waste it",
      description:
        "Experience the joy of having smart time tracking tools built into your payroll system.",
      linkText: "Learn about Time Tracking",
      link: process.env.GATSBY_SITE_URL + '/time',
      video: video_time,
      quote:
        "We love the employee mobile app. It means we can all record time and keep track of our hours from wherever we’re working.",
      name: "Miranda Hitchings",
      company: "Dignity",
      pic: "Dignity_Circle.png",
    },
    {
      title: "Take a break from employee admin",
      description:
        "Give your employees access to complete onboarding, view payslips and leave balances, and easily submit timesheets, leave requests, and expense claims from the employee app.",
      linkText: "Learn about Employee Access",
      link: process.env.GATSBY_SITE_URL + '/employee-self-service',
      video: video_employee,
      quote:
        "Employees can apply for leave through their employee portal, and we are notified in real-time. The level of transparency is a real benefit for us.",
      name: "Kieran Wallace",
      company: "1154",
      pic: "Kieran_Circle.png",
    },
  ];

  const Integrations = [
    {
      logo: Droppah_Logo,
      colour: "#000000",
      square: true,
    },
    {
      logo: Xero_Logo,
      colour: "#1AB4D7",
      square: true,
    },
    {
      logo: MyHR_Logo,
      colour: "#384FE5",
      square: false,
    },
    {
      logo: IRD_Logo,
      colour: "#008798",
      square: false,
    },
    {
      logo: Akahu_Logo,
      colour: "#8232FF",
      square: true,
    },
    {
      logo: PowerBI_Logo,
      colour: "#F3CF00",
      square: false,
    },
  ];

  const Industries = [
    {
      icon: <IoRestaurantOutline />,
      industry: "Hospitality",
      description:
        "Stay on top of your labour costs with intelligent payroll built for variable hour employees.",
      link: "/hospitality",
    },
    {
      icon: <IoConstructOutline />,
      industry: "Trades",
      description:
        "Don’t waste your evenings on admin. Increase payroll accuracy with on-site GPS time tracking.",
      link: "/trades-payroll",
    },
    {
      icon: <IoFastFoodOutline />,
      industry: "Franchises",
      description:
        "Look after your staff, get their entitlements right and understand wage costs across the group.",
      link: "/franchise-payroll",
    },
    {
      icon: <IoSunnyOutline />,
      industry: "Horticulture",
      description: "",
      link: "/horticulture",
    },
    {
      icon: <IoPeopleOutline />,
      industry: "Recruitment",
      description: "",
      link: "/recruitment",
    },
    {
      icon: <IoRestaurantOutline />,
      industry: "Manufacturing",
      description: "",
      link: "/manufacturing",
    },
    {
      icon: <IoChatbubblesOutline />,
      industry: "Bookkeepers",
      description: "",
      link: "/icnzb",
    },
    {
      icon: <IoPartlySunnyOutline />,
      industry: "Farmers",
      description: "",
      link: "/farmers-payroll",
    },
    {
      icon: <IoStorefrontOutline />,
      industry: "Retail",
      description: "",
      link: "/retail",
    },
  ];

  const Customers = [
    {
      image: "1154_Banner.jpg",
      quote:
        "PayHero understands how hospitality works and joins up all the dots of the payroll process into one easy-to-use, accessible package.",
      author: "Kieran Wallace",
      company: "1154 & Scopa",
      logo: "1154_Logo.png",
      link: "/1154-and-Scopa",
      videoLink: "https://player.vimeo.com/video/774900861",
    },
    {
      image: "HamishAndRiannFromConvex_Wide.jpg",
      quote:
        "We’re usually reluctant to endorse payroll software. But PayHero gets the detail right, at the same time as being exceptionally easy to use.",
      author: "Hamish Mexted",
      company: "Convex Accounting",
      logo: "ConvexAccountingLogo.png",
      link: "",
      videoLink: "",
    },
  ];

  const Support = [
    {
      icon: <IoDesktopOutline />,
      type: "Demo",
      description:
        "Try our interactive demo account with dummy data already loaded.",
      link: "https://payhero.co.nz/interactive-demo",
      linkText: "Start Now",
    },
    // {
    //   icon: <IoPeopleCircleOutline />,
    //   type: "Webinars",
    //   description:
    //     "See PayHero in action and learn how to set up your account.",
    //   link: "/events",
    //   linkText: "Book Now",
    // },
    {
      icon: <IoPlayOutline />,
      type: "Videos",
      description:
        "See PayHero in action with on-demand support videos and customer stories.",
      link: "/tutorials",
      linkText: "Watch Now",
    },
    {
      icon: <IoHelpBuoyOutline />,
      type: "Guides",
      description:
        "Visit our support centre for detailed articles to help you get started and use PayHero.",
      link: "https://support.payhero.co.nz/hc/en-us",
      linkText: "Read Now",
    },
    {
      icon: <IoBookOutline />,
      type: "Resources",
      description:
        "Read helpful guides, product updates and blog posts in our resource library.",
      link: "/blog",
      linkText: "Browse Now",
    },
  ];

  return (
    <Layout>
      <Seo
        title="PayHero | Compliant, Accurate & Easy to Use Payroll Software"
        description="Track hourly work with ease and automatically calculate wages and entitlements for part time, casual, contract or shift workers."
        pathname={props.location.pathname}
      />
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "http://schema.org",
              "@type": "Organization",
              "url": "${process.env.GATSBY_SITE_URL}",
              "name": "PayHero",
              "legalName": "PayHero",
              "parentOrganization": "FlexiTime",
              "description": "Track hourly work with ease and automatically calculate wages and entitlements for part time, casual, contract or shift workers.",
              "logo": "${process.env.GATSBY_SITE_URL +
            `/metaImages/PayHero_SchemaLogo.png`}",
              "foundingDate": "2018",
              "founders": [
                {
                "@type": "Person",
                "name": "Robert Owen"
                } 
              ],
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "11 Chews Lane",
                "addressLocality": "Willis Street",
                "addressRegion": "Wellington",
                "postalCode": "6143",
                "addressCountry": "New Zealand"
              },
              "contactPoint": {
                "@type": "ContactPoint",
                "contactType": "PayHero Sales",
                "email": "sales@payhero.co.nz",
                "areaServed" : "NZ"
              },
              "sameAs": [ 
                "https://www.facebook.com/PayHeroHQ/",
                "https://twitter.com/FlexiTimeTeam",
                "https://www.linkedin.com/showcase/payheronz/",
                "https://www.youtube.com/channel/UCYP8fYDx74HaMc2RzuLojhg"
              ],
              "review": [
              {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "5"
                },
                "author": {
                  "@type": "Person",
                  "name": "Pyes Pa Doctors"
                }
              },
              {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "5"
                },
                "author": {
                  "@type": "Person",
                  "name": "Cindy Hickmott"
                }
              },
              {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "5"
                },
                "author": {
                  "@type": "Person",
                  "name": "Brian Murphy"
                }
              },
              {
                "@type": "Review",
                "reviewRating": {
                  "@type": "Rating",
                  "ratingValue": "5"
                },
                "author": {
                  "@type": "Person",
                  "name": "New York Grill"
                }
              }
              ],
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "5",
                "bestRating": "5",
                "worstRating": "1",
                "ratingCount": "9"
              }
            }
          `}
        </script>
      </Helmet>
      <Container>
        <StaticQuery
          query={graphql`
            query {
              desktop: file(relativePath: { eq: "home/Cafe.jpg" }) { 
                childImageSharp {
                  fluid(quality: 100, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          `}
          render={(data) => {
            const imageData = data.desktop.childImageSharp.fluid;
            return (
              <BackgroundImage
                Tag="section"
                fluid={imageData}
                backgroundColor={`#fff`}
                css={{
                  backgroundPosition: "top",
                }}
              >
                <Wrapper>
                  <Box css={{ "padding-top": "18vh" }}>
                    <img
                      className="-center -addShadow"
                      src={Autopilot}
                      alt="Put your payroll on Autopilot"
                    />
                    <Box stackGap={30}>
                      <Button
                        className="primary -xl -center gtm-cta"
                        to="/signup"
                      >
                        Start Free Trial
                      </Button>
                      <p className="-textCenter" css={{ color: "#fff" }}>
                        14 Days Free · First Pay Walkthrough · No Credit Card
                        Required
                      </p>
                    </Box>
                  </Box>
                  <AwardBadge src={PayrollAwardBadge} alt="" />
                </Wrapper>
              </BackgroundImage>
            );
          }}
        />
      </Container>
      <Container>
        <Wrapper css={{ 'padding-top': '70px', 'padding-bottom': '50px' }}>
          <LogoScroller>
            <Image
              filename="CustomerLogoBanner.png"
              alt="PayHero Customers"
              maxWidth={1200}
              centerImage
            />
          </LogoScroller>
          <Box stackGap={200}>
            {Features.map((item, i) => {
              return (
                <Row
                  stackGap={120}
                  alignCenter
                  key={i}
                  flipRow={i % 2 !== 0 ? true : false}
                >
                  <Box stackGap={40} size={50}>
                    <Box stackGap={30}>
                      <Box stackGap={20}>
                        <h2 className="h1 -fontBold -fontLarge">
                          {item.title}
                        </h2>
                        <h3 css={{ color: "#333" }}>{item.description}</h3>
                      </Box>
                      <Link className="link-floating link-arrow" to={item.link}>
                        {item.linkText} <IoArrowForward />
                      </Link>
                    </Box>
                    <CustomerQuote
                      quote={item.quote}
                      name={item.name}
                      company={item.company}
                      pic={item.pic}
                    />
                  </Box>
                  <Box size={50}>
                    <Box css={{ zIndex: "90", overflow: "hidden" }}>
                      <video
                        id={i}
                        loop
                        muted
                        playsInline
                        css={{
                          width: "calc(100% + 2px);",
                          marginLeft: "-1px",
                          marginTop: "-2px",
                        }}
                      >
                        <source src={item.video} type="video/mp4" />
                      </video>
                    </Box>
                    <BlueInterest
                      className={i % 2 !== 0 ? "-right" : "-left"}
                    />
                  </Box>
                </Row>
              );
            })}
          </Box>
        </Wrapper>
      </Container>
      <Container className="secondary" css={{ marginTop: "50px" }}>
        <Wrapper>
          <h2
            css={{ maxWidth: "900px" }}
            className="-center -textCenter -fontLarge"
          >
            Super powerful integrations
          </h2>
          <Row css={{ alignItems: "stretch" }} stackGap={40}>
            {Integrations.map((item, i) => {
              return (
                <IntegrationBox key={i} css={{ backgroundColor: item.colour }}>
                  <img className={item.square && "-square"} src={item.logo} />
                </IntegrationBox>
              );
            })}
          </Row>
          <Link className="link-floating link-arrow -center" to="/add-ons">
            See Full List of Payroll Integrations <IoArrowForward />
          </Link>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={60}>
            <h2
              css={{ maxWidth: "900px" }}
              className="-center -textCenter -fontLarge"
            >
              Kiwi businesses large and small rely on PayHero
            </h2>
            <CustomSwiper
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              loop={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              {Customers.map((item, i) => {
                return (
                  <CustomSwiperSlide>
                    <SlideContent>
                      <Box className="customer-image">
                        <Image filename={item.image} alt="" />
                      </Box>
                      <Box className="customer-quote" stackGap={35} size={38}>
                        <h2>"{item.quote}"</h2>
                        <Row alignCenter stackGap={10} noBreak>
                          <Box css={{ flex: "1 1 auto" }}>
                            <p
                              className="-fontNormal"
                              css={{ marginBottom: "0" }}
                            >
                              <b>{item.author}</b>
                              <br />
                              {item.company}
                            </p>
                          </Box>
                          <Box
                            css={{
                              width: "100%",
                              maxWidth: "80px",
                            }}
                          >
                            <Image filename={item.logo} alt={item.company} />
                          </Box>
                        </Row>
                        {(item.link || item.videoLink) && (
                          <Row stackGap={40} alignCenter>
                            {item.link && (
                              <Link
                                className="link-floating link-arrow"
                                to={`/case-studies` + item.link}
                              >
                                Read Case Study <IoArrowForward />
                              </Link>
                            )}
                            {item.videoLink && (
                              <a
                                className="link-floating"
                                onClick={() => {
                                  handleModal(item.videoLink, item.company);
                                }}
                              >
                                <IoPlay css={{ top: "2px" }} /> Play Video
                              </a>
                            )}
                          </Row>
                        )}
                      </Box>
                    </SlideContent>
                  </CustomSwiperSlide>
                );
              })}
              <div class="prev">
                <IoArrowBack />
              </div>
              <div class="next">
                <IoArrowForward />
              </div>
            </CustomSwiper>
            <Link
              className="link-floating link-arrow -center"
              to="/case-studies"
            >
              Read Customer Stories <IoArrowForward />
            </Link>
          </Box>
        </Wrapper>
      </Container>
      <Container bg="Primary">
        <Wrapper>
          <h2 className="-fontLarge -textCenter" css={{ color: "#fff" }}>
            Take a deeper dive
          </h2>
          <Row css={{ alignItems: "stretch" }} stackGap={20}>
            {Support.map((item, i) => {
              return (
                <SupportBox size={25}>
                  <Box stackGap={10}>
                    {item.icon}
                    <Box stackGap={5}>
                      <h2>{item.type}</h2>
                      <p>{item.description}</p>
                    </Box>
                  </Box>
                  {item.type === "Guides" || "Demo" ? (
                    <a
                      className="link-floating"
                      href={item.link}
                      target="_blank"
                    >
                      {item.linkText} <IoMdOpen css={{ top: "2px" }} />
                    </a>
                  ) : (
                    <Link className="link-floating link-arrow" to={item.link}>
                      {item.linkText} <IoArrowForward />
                    </Link>
                  )}
                </SupportBox>
              );
            })}
          </Row>
        </Wrapper>
      </Container>
      <Container className="secondary">
        <Wrapper>
          <h2 className="-fontLarge -textCenter" css={{ color: "#fff" }}>
            Hear why accountants and bookkeepers love PayHero
          </h2>
          <div className="-center -addShadow" css={{ maxWidth: "1000px" }}>
            <IframeContainer
              src="https://player.vimeo.com/video/999367110"
              title="What Makes PayHero the Best Payroll Software? | Partner Testimonials"
              noBottomRadius
            />
          </div>
        </Wrapper>
      </Container>
      <Container>
        <Wrapper>
          <Box stackGap={10} className="-textCenter">
            <h2 className="-fontLarge">Payroll for your industry</h2>
            <h3 css={{ color: "#333" }}>
              Simplify payroll & track time with ease for your full time, part
              time, casual, contract or shift workers.
            </h3>
          </Box>
          <Row stackGap={30} isWrap justify="flex-start">
            {Industries.map((item, i) => {
              return (
                <Box size={100 / 3} offset={20}>
                  <IndustryBox
                    className={!item.description && "-flex"}
                    to={item.link}
                    key={i}
                  >
                    {item.icon}
                    <Box stackGap={5}>
                      <Row
                        alignCenter
                        justify="flex-start"
                        stackGap={10}
                        noBreak
                      >
                        <h2>{item.industry}</h2>
                        <IoArrowForward className="-arrow" />
                      </Row>
                      {item.description && <p>{item.description}</p>}
                    </Box>
                  </IndustryBox>
                </Box>
              );
            })}
          </Row>
        </Wrapper>
      </Container>
      <CallToAction
        title={[
          "Pay Employees Right ",
          <span className="-fontPrimary">with PayHero</span>,
        ]}
        showSalesButton
        hideDesc
      />
      <Container>
        <Wrapper stackGap={20}>
          <p className="-textCenter" css={{ marginBottom: "0" }}>
            * As voted by members for the Institute of Certified New Zealand
            Bookkeepers Annual Excellence Awards.
          </p>
        </Wrapper>
      </Container>
      <Modal
        isActive={itemModalOpen}
        embedURL={embedLink + `?autoplay=1`}
        embedTitle={embedTitle + ` | PayHero Customer Story`}
        handleClose={() => setItemModalOpen(false)}
        isVideo
      />
    </Layout>
  );
};

export default Home;
// 